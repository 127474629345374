import css from "../assets/skills/css.svg";
import figma from "../assets/skills/figma.svg";
import github from "../assets/skills/github.svg";
import javascript from "../assets/skills/javascript.svg";
import nodejs from "../assets/skills/nodejs.svg";
import react from "../assets/skills/react.svg";
import tailwind from "../assets/skills/tailwind.svg";
import typescript from "../assets/skills/typescript.svg";

export const SKILLS = [
  {
    image: css,
    alt: "css",
  },
  {
    image: figma,
    alt: "figma",
  },

  {
    image: github,
    alt: "github",
  },
  {
    image: tailwind,
    alt: "tailwind",
  },
  {
    image: javascript,
    alt: "javascript",
  },
  {
    image: nodejs,
    alt: "nodejs",
  },
  {
    image: react,
    alt: "react",
  },
  {
    image: typescript,
    alt: "typescript",
  },
];
